.image-file-uploader {
    margin-bottom: 30px;
    &-title {
        font-size: 20px;
        margin-bottom: 5px;
    }
    &-desc {
        font-size: 14px;
        margin-bottom: 30px;
        color: #7B809A;
        margin-bottom: 20px;
    }
    .uploader-file-input {
        display: none;
    }
    .placehoder-img {
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        outline: 2px dotted #a7a7a7;
        border: 5px solid white;
        transition: all 0.3s ease-in-out;
        &:hover {
            background-color: #D8D8D8;
        }
        svg {
            width: 70px;
            height: 70px;
            fill: #b5b5b5;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .placeholder-circle {
        max-width: 150px;
        width: 100%;
        height: 150px;
        border-radius: 50%;
        img {
            border-radius: 50%;
        }
    }
    .placeholder-feature {
        max-width: 278px;
        width: 100%;
        height: 224px;
    }
    .placeholder-banner {
        max-width: 590px;
        width: 100%;
        height: 232px;
    }
}