.create-project-container {
    .create-project-content {
        .create-form-field-desc-text {
            font-size: 14px;
            margin-top: 7px;
            color: #7B809A;
            font-weight: 300;
        }
        .field-container {
            margin-bottom: 30px;
            .name-label {
                margin-bottom: 10px;
            }
        }
        .input-container {
            background-color: white;
            .input-field {
                width: 100%;
                margin: 0;
            }
        }
        .select-container {
            margin-bottom: 30px;
            .MuiInputLabel-root {
                font-size: 18px;
                color: #344767;
            }
            .MuiNativeSelect-select {
                width: 150px;
            }
        }
        .form-group {
            align-items: center;
            // border: 1px solid #ccc;
            background: white;
            border-radius: 8px;
            padding: 0 10px;
            margin-top: 10px;
            // 
            position: relative;
            height: 46px;
            .input-label {
                z-index: 10;
                pointer-events: none;
            }
            .input-field {
                margin: 0;
                position: absolute;
                left: 0;
                /* padding-left: 100px; */
                top: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                input {
                    height: 22px;
                }
            }
        }
        .form-group-instagram {
            input {
                padding-left: 192px;
            }
        }
        .input-field-prepend-text {
            background-color: white;
            width: 100%;
        }
        .selected-files-list {
            list-style: none;
            margin-top: 15px;
            display: grid;
            grid-template-columns: max-content;
            gap: 10px;
            li {
                background: #ccc;
                padding: 3px 10px;
                border-radius: 5px;
                display: inline-flex;
                font-size: 14px;
            }
        }
    }
}