.project-grid-card {
    background-color: white !important;
    border-radius: 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    .project-card-img-container {
        width: 90%;
        margin: auto;
        border-radius: 15px;
        position: relative;
        transform: translateY(-20px);
        .favorite-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 20;
        }
        .gallery-img {
            width: 100%;
            border-radius: inherit;
        }
        .profile-img {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 150px;
            transform: translate(-50%, 35%);
            border-radius: 50%;
        }
    }
    .project-card-body {
        margin-top: 25px;
        padding: 10px 20px;
        .project-title {
            text-align: center;
            margin-bottom: 10px;
        }
        .project-description {
            font-size: 14px;
            text-align: justify;
        }
    }
    .project-card-footer {
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        padding: 10px 20px;
        border-top: 1px solid rgb(228, 228, 228);
        .project-location-info {
            display: flex;
            align-items: center;
        }
    }
}