.withdrawal-methods {
    .withdrawal-methods-img {
        width: 50px;
    }
    .withdrawal-methods-amount {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-left: 20px;
        label {
            font-weight: bold;
        }
    }
    .withdrawal-methods-text {
        flex-basis: 140px;
    }
    .css-14f9zar-MuiListItemSecondaryAction-root {
        right: 20px;
    }
    .MuiListItemButton-dense {
        gap: 10px;
    }
}