.billing-page-container {
    .billing-page-title {
        margin-bottom: 40px;
        h5 {
            margin-bottom: 10px;
        }
        .verify-status-container {
            display: flex;
            align-items: center;
            gap: 10px;
            .verify-status-dot {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: red;
                &.verified {
                    background-color: rgb(46, 238, 46);
                }
            }
            .verify-status-text {
                font-size: 14px;
            }
        }
    }
    .balance-card {
        background-color: #11151A;
        border-radius: 20px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
        padding: 20px;
        position: relative;
        .balance-card-menu-icon {
            position: absolute;
            top: 0;
            right: 0;
        }
        .img-container {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 128, 0, 0.5);
            margin-bottom: 20px;
        }
        svg {
            fill: white
        }
        p {
            font-size: 14px;
            color: rgb(212, 212, 212);
        }
        h4 {
            color: white;
        }
    }
    .recent-transactions-card {
        background-color: white;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        padding: 40px;
        .recent-transactions-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .recent-transactions-dates {
                display: flex;
                align-items: center;
                gap: 100px;
                @media(max-width: 800px) {
                    gap: 10px;
                }
                @media(max-width: 500px) {
                    flex-direction: column;
                }
                >div {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
            }
        }
        .recent-transactions-list-order {
            margin-top: 30px;
            margin-bottom: 10px;
            text-transform: uppercase;
        }
        .recent-transactions-order {
            text-transform: uppercase;
            margin-top: 30px;
        }
        .MuiListItem-root {
            display: grid;
            grid-template-columns: auto auto 1fr 1fr;
            align-items: center;
            justify-items: end;
            padding-block: 15px;
        }
        .recent-transactions-minus {
            background-color: #ff0d0d42;
        }
        .recent-transactions-plus {
            background-color: #00950052;
        }
        .recent-transactions-pdf {
            display: flex;
            align-items: center;
            gap: 10px;
            span {
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
        .recent-transactions-amount {
            font-size: 14px;
            font-weight: bold;
        }
        .recent-transactions-list-text {
            span {
                font-size: 14px;
                font-weight: bold;
            }
            p {
                font-size: 14px;
            }
        }
    }
}