.project-list-card {
    display: flex;
    gap: 20px;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    .project-card-img-container {
        position: relative;
        min-width: 200px;
        height: 150px;
        img {
            width: 100%;
            height: 100%;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        .favorite-icon {
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }
    .project-list-card-content {
        display: flex;
        flex-direction: column;
        padding-block: 15px;
        padding-right: 25px;
        .project-title {
            font-size: 18px;
        }
        .project-description {
            font-size: 14px;
            flex: 1;
        }
        .project-list-footer {
            display: flex;
            align-items: center;
            // justify-content: flex-end;
            opacity: 0.8;
            gap: 30px;
            .project-list-location-info {
                display: flex;
                align-items: center;
                font-size: 14px;
                white-space: nowrap;
            }
            .project-list-project-price {
                font-size: 14px;
            }
        }
    }
}