.all-projects-container {
    .top-banner {
        .list-view-btn {
            transform: translateY(-10px);
            color: white;
        }
        .MuiFormControlLabel-label {
            color: white;
            margin-left: 0;
        }
        .MuiSelect-select {
            color: white;
        }
        .project-banner-right {
            >.MuiGrid-item {
                padding-top: 8px;
            }
        }
    }
}