.user-varification-document-card {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    background-color: white;
    padding: 15px 10px;
    padding-bottom: 0;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    .img-container {
        background-color: #11151A;
        padding: 10px;
        width: 64px;
        height: 48px;
        border-radius: 8px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        margin-top: 0;
    }
    h5 {
        line-height: 1.3;
        font-size: 16px;
    }
    p {
        font-size: 10px;
        color: #7B809A;
        margin-bottom: 10px;
    }
    .MuiDivider-root {
        margin-bottom: 0;
        margin-top: auto;
    }
}