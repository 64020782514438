.single-project-container {
    .single-project-stats-container {
        .single-project-stats-left {
            margin-top: 15px;
            div {
                font-size: 16px;
                span {
                    font-size: 12px;
                }
            }
        }
        .single-project-stats-center {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 10px;
            .img-container {
                width: 150px;
                height: 150px;
                margin-top: -81px;
                z-index: 1000;
                @media(max-width: 767px) {
                    margin-top: auto;
                }
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }
            div {
                font-size: 16px;
                span {
                    font-size: 12px;
                }
            }
        }
        .single-project-stats-right {
            margin-top: 15px;
        }
    }
}