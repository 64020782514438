.single-project-detail-container {
    .project-img-container {
        width: 80%;
        margin: auto;
        img {
            max-width: 100%;
            border-radius: 15px;
        }
    }
    .activity-data-table-container {
        svg {
            display: block;
        }
        .MuiAutocomplete-root {
            .MuiTypography-caption {
                color: black;
            }
        }
        th {
            div {
                // color: black;
                font-weight: bold;
            }
        }
    }
}