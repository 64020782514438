.transactions-list-container {
    .transactions-card-title {
        margin: 20px;
        margin-bottom: 0;
        p {
            font-size: 14px;
            color: #7B809A;
        }
    }
    th {
        div {
            color: black;
            font-weight: bold;
        }
    }
}