.user-varification-location {
    .user-varification-location-select {
        display: flex;
        align-items: flex-end;
        >.MuiBox-root {
            // width: 100%;
        }
        .MuiSelect-icon {
            display: block;
        }
    }
    // background-color: red;
}