.edit-fields-container {
    .field-label {
        white-space: nowrap;
        overflow: visible;
        font-weight: bold;
        color: inherit;
        min-width: 70px;
    }
    .css-rjulde-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select {
        grid-template-columns: auto 1fr;
        gap: 5px;
        padding-left: 0 !important;
    }
    .MuiSelect-icon {
        display: inline-block !important;
        // width: 25px !important;
        // height: 25px !important;
    }
}