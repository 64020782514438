.project-preview-container {
    .project-preview-top {
        color: white;
        text-align: center;
        padding: 15px 30px;
    }
    .biota-btn-icon {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        border-right: 1px solid #024959;
        height: 100%;
        padding-right: 10px;
        width: 30px;
    }
}