.react-responsive-modal-root {
    z-index: 2000 !important;
}

#transactions-modal {
    border-radius: 15px;
    .MuiTabs-root {
        margin-bottom: 10px;
    }
    .transaction-list-img {
        max-width: 100%;
    }
}